import React from 'react';
import styled from '@emotion/styled';

import { useStore } from '../../lib/store';

const StyledStatus = styled.div`
    padding: 5px 10px;
    text-align: center;
    color: #fff;
    background-color: ${props => props.level === 'error' ? '#ff4e4e' : '#1a86f9'};
    font-size: 0.9em;
`;

export default () => {
    const [state] = useStore();

    if (!state.statusMessage) {
        return null;
    }

    return (
        <StyledStatus level={state.statusLevel}>
            {state.statusMessage}
        </StyledStatus>
    );
};
