import React from 'react';
import { Flex, Box } from 'reflexbox';
import styled from '@emotion/styled';

import { useStore } from '../../lib/store';

const StyledNavigation = styled.div`
    background: #eee;
    padding: 5px 10px;
    font-size: .9em;
`;

export default () => {
    const [state] = useStore();

    const prevDisabled = state.currentCandidateIdx === 0;
    const nextDisabled = state.currentCandidateIdx + 1 === state.summary.numTotal;

    return (
        <StyledNavigation>
            <Flex flexWrap='wrap' style={{ textAlign: 'left' }}>
                <Box width={1 / 3}>
                    <div>
                        <button disabled={prevDisabled} onClick={() => state.prevCandidateHandler()}>prev candidate</button>
                    </div>
                    <div>
                        <button disabled={prevDisabled} onClick={() => state.prevConsistentHandler()}>prev consistent</button>
                    </div>
                </Box>
                <Box width={1 / 3} style={{ textAlign: 'center' }}>
                    Execution candidate no. {state.currentCandidateIdx + 1} of {state.summary.numTotal}
                </Box>
                <Box width={1 / 3} style={{ textAlign: 'right' }}>
                    <div>
                        <button disabled={nextDisabled} onClick={() => state.nextCandidateHandler()}>next candidate</button>
                    </div>
                    <div>
                        <button disabled={nextDisabled} onClick={() => state.nextConsistentHandler()}>next consistent</button>
                    </div>
                </Box>
            </Flex>
        </StyledNavigation>
    );
};
