import React from 'react';
import CodeMirror from 'codemirror';

import 'codemirror/mode/clike/clike';

export default class CodeMirrorComponent extends React.Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    handleChange() {
        if (this.props.onChange) {
            this.props.onChange(this.instance.getValue());
        }
    }

    componentDidMount() {
        this.instance = CodeMirror.fromTextArea(this.ref.current, {
            readOnly: this.props.readOnly
        });

        this.instance.on('change', this.handleChange.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            const scrollPosition = this.instance.getScrollInfo();
            const anchor = this.instance.getCursor('anchor');
            const head = this.instance.getCursor('head');
            this.instance.setValue(this.props.value);
            this.instance.setSelection(anchor, head);
            this.instance.scrollTo(scrollPosition.left, scrollPosition.top);
        }

        if (this.props.readOnly !== prevProps.readOnly) {
            this.instance.setOption('readOnly', this.props.readOnly);
        }
    }

    render() {
        return <textarea defaultValue={this.props.value} ref={this.ref} />;
    }
}
