import React, { useEffect, useState } from 'react';

import { useStore } from '../../lib/store';

import examples from '../../lib/examples.codegen';

const OptGroup = ({ name, examples }) => {
    return (
        <optgroup label={name}>
            {
                Object.keys(examples).map((example) => (
                    <option key={example} value={`examples/${name}/${example}`}>{example}</option>
                ))
            }
        </optgroup >
    );
};

const OptGroups = () => {
    return Object.entries(examples).map(([groupName, groupExamples]) => {
        return <OptGroup key={groupName} name={groupName} examples={groupExamples} />;
    });
};

export default () => {
    const [state, actions] = useStore();

    const [value, setValue] = useState('examples/Paper/sc_atomics');

    useEffect(() => {
        const [_, groupName, exampleName] = value.split('/');
        actions.setEditorContent(examples[groupName][exampleName]);
    }, [value]);

    return (
        <select
            value={value}
            onChange={e => setValue(e.target.value)}
            disabled={!state.interactive}>
            <OptGroups />
        </select>
    );
};
