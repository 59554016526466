import React from 'react';
import styled from '@emotion/styled';

import CodeMirror from './editor/CodeMirror';
import { useStore } from '../../lib/store';

const StyledEditor = styled.div`
    .CodeMirror {
        font-family: 'Roboto Mono', monospace;
        font-size: .9em;
        height: 400px;
    }
`;

export default () => {
    const [state, actions] = useStore();

    return (
        <StyledEditor>
            <CodeMirror
                value={state.editorContent}
                readOnly={!state.interactive}
                onChange={actions.setEditorContent} />
        </StyledEditor>
    );
};
