import React from 'react';
import { Flex, Box } from 'reflexbox';
import styled from '@emotion/styled';

import { useStore } from '../../lib/store';
import Examples from './Examples';

const StyledControls = styled.div`
    background: #eee;
    padding: 5px 10px;
`;

export default () => {
    const [state] = useStore();

    return (
        <StyledControls>
            <Flex flexWrap='wrap'>
                <Box width={1 / 2}>
                    <button onClick={() => state.interactButtonHandler()} disabled={!state.interactive}>RUN</button>
                    <button onClick={() => state.resetButtonHandler()} disabled={state.interactive}>RESET</button>
                </Box>
                <Box width={1 / 2}>
                    <Examples />
                </Box>
            </Flex>
        </StyledControls>
    );
};
