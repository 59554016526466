import React from 'react';
import styled from '@emotion/styled';

import { useStore } from '../lib/store';
import { LayoutInner } from './Layout';

import { cppmem } from '../../cppmem/web/cppmem.js';

const StyledLegacy = styled.div`
    background: #eee;
`;

class LegacyInner extends React.Component {
    constructor(props) {
        super(props);

        this.legacyRef = React.createRef();
    }

    componentDidMount() {
        const self = this;

        cppmem.start({
            get state() {
                return self.props.state;
            },

            get actions() {
                return self.props.actions;
            }
        });
    }

    render() {
        return (
            <div>
                <div id="legacy" ref={this.legacyRef}></div>
            </div>
        );
    }
};

export default () => {
    const [state, actions] = useStore();

    return (
        <StyledLegacy>
            <LayoutInner>
                <LegacyInner state={state} actions={actions} />
            </LayoutInner>
        </StyledLegacy>
    );
};
