import React, { useState } from 'react';

import Header from './Header';
import Main from './Main';
import Legacy from './Legacy';

export default () => {

    return (
        <div>
            <Header />

            <Main />

            <Legacy />
        </div>
    );
};
