import { createStore, createHook } from 'react-sweet-state';

const initialState = {
    editorContent: '',

    imageSource: null,
    imageRenderEngine: null,

    statusMessage: null,
    statusLevel: null,

    interactive: true,

    summary: null,
    currentCandidateIdx: null,

    interactButtonHandler: () => { },
    resetButtonHandler: () => { },

    prevConsistentHandler: () => { },
    prevCandidateHandler: () => { },
    nextCandidateHandler: () => { },
    nextConsistentHandler: () => { },
};

const actions = {
    setEditorContent: (content) => ({ setState }) => {
        setState({ editorContent: content });
    },

    renderImage: (source, engine) => async ({ setState }) => {
        setState({ imageSource: source, imageRenderEngine: engine });
    },

    setInteractive: (interactive) => ({ setState }) => {
        setState({ interactive, statusMessage: null });
    },

    setStatus: (level, message) => ({ setState }) => {
        setState({ statusLevel: level, statusMessage: message });
    },

    setSummary: (json) => ({ setState }) => {
        const [_, numTotal, numConsistent, numRaceFree] = JSON.parse(json);
        setState({ summary: { numTotal, numConsistent, numRaceFree } });
    },

    setInteractButtonHandler: (handler) => ({ setState }) => {
        setState({ interactButtonHandler: handler });
    },

    setResetButtonHandler: (handler) => ({ setState }) => {
        setState({ resetButtonHandler: handler });
    },

    setCurrentCandidateIdx: (currentCandidateIdx) => ({ setState }) => {
        setState({ currentCandidateIdx });
    },

    setNavigationHandlers: (prevConsistentHandler, prevCandidateHandler, nextCandidateHandler, nextConsistentHandler) => ({ setState }) => {
        setState({ prevConsistentHandler, prevCandidateHandler, nextCandidateHandler, nextConsistentHandler });
    },
};

const Store = createStore({ initialState, actions });

export const useStore = createHook(Store);
