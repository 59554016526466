import React from 'react';
import styled from '@emotion/styled';

import { LayoutInner } from './Layout';

const StyledHeader = styled.div`
    color: #fff;
    background: #333;
`;

const StyledTitle = styled.h1`
    font-size: 1em;
    text-align: left;
    padding: 0 16px;
`;

export default () => {
    return (
        <StyledHeader>
            <LayoutInner>
                <StyledTitle>CppMem: Interactive C/C++ memory model</StyledTitle>
            </LayoutInner>
        </StyledHeader>
    );
};
