import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Viz from 'viz.js';

import { useStore } from '../../lib/store';

const StyledImage = styled.div`
    text-align: center;
    overflow: scroll;
    height: 376px;
`;

const viz = new Viz({ workerURL: 'worker.js' });

export default () => {
    const [state] = useStore();

    const [data, setData] = useState();

    useEffect(() => {
        if (state.imageSource) {
            viz.renderString(state.imageSource, { engine: state.imageRenderEngine })
                .then(svg => setData(svg));
        }
    }, [state.imageSource, state.imageRenderEngine]);

    return (
        <StyledImage>
            {data ? <div dangerouslySetInnerHTML={{ __html: data }} /> : null}
        </StyledImage>
    );
};
