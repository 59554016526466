import React from 'react';
import { Flex, Box } from 'reflexbox';

import { useStore } from '../lib/store';
import { LayoutInner } from './Layout';
import Editor from './main/Editor';
import Controls from './main/Controls';
import Summary from './main/Summary';
import Image from './main/Image';
import Status from './main/Status';
import Navigation from './main/Navigation';

const Left = () => {
    return (
        <Box width={1 / 2} p={3}>
            <Editor />
            <Controls />
            <Status />
        </Box>
    );
};

const Right = () => {
    const [state] = useStore();

    return (
        <Box width={1 / 2} p={3}>
            {
                state.interactive ? null : (<div><Summary /><Image /><Navigation /></div>)
            }
        </Box>
    );
}

export default () => {
    return (
        <LayoutInner>
            <Flex flexWrap='wrap'>
                <Left />
                <Right />
            </Flex>
        </LayoutInner>
    );
};
