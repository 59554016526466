import React from 'react';
import { Flex, Box } from 'reflexbox';
import styled from '@emotion/styled';

import { useStore } from '../../lib/store';

const StyledCell = styled.div`
    text-align: center;
    font-size: .8em;
    text-transform: uppercase;
    color: ${props => props.warning ? '#fff' : '#666'};
    background-color: ${props => props.warning ? '#ff4e4e' : '#fff'};
    margin-bottom: 10px;
`;

const Inner = ({ summary }) => {
    if (!summary) {
        return null;
    }

    const notRaceFree = summary.numConsistent > 0 &&
        summary.numConsistent > summary.numRaceFree;

    return (
        <Flex flexWrap='wrap'>
            <Box width={1 / 3} p={1}>
                <StyledCell>{`${summary.numTotal} total`}</StyledCell>
            </Box>
            <Box width={1 / 3} p={1}>
                <StyledCell>{`${summary.numConsistent} consistent`}</StyledCell>
            </Box>
            <Box width={1 / 3} p={1}>
                <StyledCell warning={notRaceFree}>{`${summary.numRaceFree} race-free`}</StyledCell>
            </Box>
        </Flex>
    );
};

export default () => {
    const [state] = useStore();

    return <Inner summary={state.summary} />;
};
